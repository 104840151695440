<script>
import { mapGetters } from 'vuex';
export default {
    data() {
        return {
            form: {
                file_real_name: '',
                file_type: ''
            }
        };
    },
    computed: {
        ...mapGetters(['materialTypeList'])
    },
    methods: {
        onSubmit() {
            this.$emit('search');
        },
        getValue() {
            return Object.assign({}, this.form);
        }
    }
};
</script>
<template>
    <el-form :inline="true" size="mini" :model="form" class="demo-form-inline">
        <el-form-item label="图片名称">
            <el-input v-model="form.file_real_name" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="图片类型">
            <el-select v-model="form.file_type" clearable placeholder="请选择">
                <el-option
                    v-for="item in materialTypeList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                ></el-option>
            </el-select>
        </el-form-item>
        <el-form-item>
            <el-button type="primary" @click="onSubmit">搜索</el-button>
        </el-form-item>
    </el-form>
</template>
