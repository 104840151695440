<template>
  <el-dialog
    title="添加素材"
    :visible="dialogVisible"
    width="50%"
    :before-close="close"
    :close-on-click-modal="false"
  >
    <el-form
      :model="form"
      status-icon
      ref="form"
      label-width="90px"
      class="form"
      :rules="formRules"
    >
      <el-form-item
        v-for="f in eidtableFormItems"
        :key="f.prop"
        :label="f.label"
        :prop="f.prop"
      >
        <el-upload
          v-if="f.type === 'upload'"
          ref="uploadRef"
          multiple
          list-type="picture-card"
          action="/api/cloud/filePublic/uploadFile"
          :data="uploadExtraParams"
          :headers="uploadHeaders"
          accept=".jpg,.png,.gif"
          class="apk-upload"
          :file-list="form[f.prop]"
          :auto-upload="false"
          :on-change="fileChange"
          :on-remove="fileRemove"
          :before-upload="beforeUpload"
          :on-success="successCall"
          :on-error="errorCall"
        >
          <i class="el-icon-plus"></i>
          <div slot="tip" class="el-upload__tip">支持批量上传，上传格式为jpg,png,gif文件</div>
        </el-upload>
        <el-select
          v-else
          v-model="form[f.prop]"
          placeholder="请选择"
        >
          <el-option
            v-for="item in materialTypeList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="submitForm">开始上传</el-button>
      <el-button @click="close">取消</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      dialogVisible: false,
      form: this.initForm(),
      eidtableFormItems: [
        {
          prop: "file_type",
          label: "图片类型",
          type: "select",
          placeholder: "请选择",
        },
        {
          prop: "file",
          label: "图片",
          type: "upload",
        },
      ],
      formRules: {
        file_type: [
          { required: true, message: `请选择图片类型`, trigger: "blur" },
        ],
        file: [{ required: true, message: `请选择图片`, trigger: "change" }]
      },
      fileSuccessArr: []
    };
  },
  computed: {
    ...mapState([
      'loginAccountInfo'
    ]),
    ...mapGetters(["materialTypeList"]),
    uploadExtraParams() {
      let params = {
        ...this.form
      };
      delete params.file;
      return params;
    },
    uploadHeaders() {
      return {
        token: this.loginAccountInfo.token
      }
    }
  },
  methods: {
    ...mapActions(["addOrUpdateTreeData"]),
    fileChange(file, fileList) {
        this.form.file = fileList;
    },
    fileRemove(file, fileList) {
      this.form.file = fileList;
    },
    show() {
      this.dialogVisible = true;
      this.form = this.initForm();
    },
    initForm() {
      return {
        file_type: "",
        file: []
      };
    },
    submitForm() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          this.$refs.uploadRef[0].submit();
        }
      });
    },
    close() {
      this.dialogVisible = false;
    },
    beforeUpload() {
      this.fileSuccessArr = [];
      this.$changeLoadingState(true);
    },
    successCall(ret) {
      this.$changeLoadingState(false);
      if (ret && ret.status === "success") {
        this.fileSuccessArr.push(ret.data && ret.data.id || '');
        if (this.fileSuccessArr.length === this.form.file.length) {
          // 全部上传成功
          this.close();
          this.$emit('success');
        }
      } else {
        this.$message.error(ret && ret.msg);
      }
    },
    errorCall(ret) {
      this.$changeLoadingState(false);
      this.$message.error(ret && ret.msg);
    }
  },
};
</script>
<style lang="scss" scoped>
.el-upload__tip {
  display: inline-block;
  margin-left: 10px;
  margin-top: 0px;
}
</style>
<style lang='scss'>
.form {
  background-color: #fff;
  .el-cascader--small {
    width: 100%;
  }
  .el-form-item__content {
    .el-select {
      display: block;
    }
  }
}
</style>