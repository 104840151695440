<script>
import { mapState, mapActions } from 'vuex';
import manage from './components/manage';
import ordinaryUsers from './components/ordinaryUsers';

export default {
    data() {
        return {};
    },
    computed: {
        ...mapState([
            'loginAccountInfo'
        ]),
        isManage() {
            return !!this.loginAccountInfo.is_manage;
        }
    },
    created() {
        this.getPicType();
    },
    methods: {
        ...mapActions([
            'getMaterialTypeList'
        ]),
        async getPicType() {
            try {
                this.$changeLoadingState(true);
                await this.getMaterialTypeList();
            } catch (error) {
                console.log(error);
            }
            this.$changeLoadingState(false);
        }
    },
    components: {
        manage,
        ordinaryUsers
    }
};
</script>
<template>
    <div style="height:100%;">
        <manage v-if="isManage"></manage>
        <ordinaryUsers v-else></ordinaryUsers>
    </div>
</template>
