<script>
import { mapGetters, mapActions } from "vuex";
import Pagination from "@/components/Pagination";

export default {
  data() {
    return {
      activeName: "",
      pageInfo: {
        page_num: 1,
        page_size: 18,
      },
      oldParams: {},
    };
  },
  computed: {
    ...mapGetters([
      "materialTypeList",
      "materialTableDatas",
      "materialTableListTotal",
    ]),
  },
  watch: {
    materialTypeList: function (val) {
      if (val && val.length > 0) {
        this.activeName = val[0].value;
      }
    },
		activeName: function(val, oldVal) {
			if (val !== oldVal) {
				this.getList();
			}
		}
  },
  methods: {
    ...mapActions([
      "getMaterialTableList"
    ]),
    getTableData(params) {
      this.getList(params);
    },
    async getList(params, isFirstPage) {
      if (isFirstPage) {
        this.pageInfo.page_num = 1;
      }
      let queryParams = {
        ...this.oldParams,
				file_type: this.activeName,
        ...this.pageInfo,
      };
      if (params) {
        Object.keys(this.pageInfo).forEach((key) => {
          if (params.hasOwnProperty(key)) {
            this.pageInfo[key] = params[key];
          }
        });
        queryParams = {
          ...queryParams,
          ...params,
        };
      }
      try {
        this.$changeLoadingState(true);
        await this.getMaterialTableList(queryParams);
      } catch (error) {
        console.log(error);
      }
      this.$changeLoadingState(false);
      this.oldParams = queryParams;
    },
  },
  components: {
    Pagination,
  },
};
</script>
<template>
  <div class="ordinary-pic-container">
    <el-tabs v-model="activeName">
      <el-tab-pane
        v-for="item in materialTypeList"
        :key="item.value"
        :label="item.label"
        :name="item.value"
      >
				<div class="ordinary-pic-row">
					<el-row :gutter="10">
						<el-col
							v-for="item in materialTableDatas"
							:key="item.id"
							:xs="8" :sm="6" :md="4" :lg="4" :xl="2"
						>
							<el-image :src="item.file_down_path" fit="contain" :preview-src-list="[item.file_down_path]">
								<div slot="error" class="image-slot">
									<i class="el-icon-picture-outline"></i>
								</div>
							</el-image>
						</el-col>
					</el-row>
				</div>
        <Pagination
          v-if="materialTableListTotal > 0"
          :handleSizeChange="
            (v) => getTableData({ page_size: v })
          "
          :handleCurrentPageChange="
            (v) => getTableData({ page_num: v })
          "
          :total="materialTableListTotal"
          :pageNum="pageInfo.page_num"
					:pageSizes="[18, 24, 30, 36]"
        />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<style lang="scss" scoped>
.ordinary-pic-container {
	background: #fff;
	height: 100%;
	padding: 10px;
}
.ordinary-pic-row {
	overflow-x: hidden;
	overflow-y: auto;
	height: calc(100% - 80px);
}
</style>
<style lang="scss">
.ordinary-pic-container {
	.el-tabs__nav-wrap {
		text-align: center;
		&::after {
			content: none;
		}
		.el-tabs__nav-scroll {
			display: inline-block;
		}
	}
	.el-tabs__content {
		.el-tab-pane {
			height: 100%;
		}
		.el-row {
			height: 100%;
		}
		.el-col {
			height: 30%;
			margin-top: 10px;
			.el-image {
				height: 100%;
				width: 100%;
				text-align: center;
				background: #f5f7fa;
				.image-slot {
					display: flex;
					justify-content: center;
					align-items: center;
					width: 100%;
					height: 100%;
					color: #909399;
				}
			}
		}
	}
}
</style>
